@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed|Roboto:300,400');
/* @import "~video-react/styles/scss/video-react"; */
 body {
	 font-family: 'Open Sans', sans-serif;
	 font-size: 0.9em;
	 color: #343a40;
	 font-weight: 300;
}
 .row {
	 padding-top: 20px;
	 padding-bottom: 20px;
}
 .container {
	 margin-bottom: 20px;
}
 .container-fluid {
	 margin-bottom: 20px;
}
 .fluid-wrapper {
	 background: #3db2e2;
	 color: #fff;
	 margin-bottom: 50px;
}
 .fluid-wrapper a {
	 color: #fff;
}
 .fluid-wrapper-sub {
	 background: #f5f5f5;
}
 .fluid-wrapper-color {
	 background: #e8e8e8;
	 padding-top: 20px;
}
 a {
	 color: #343a40;
}
 a:hover {
	 color: #343a40;
}
p {
  white-space: pre-line;
}
 footer {
   white-space: pre-wrap;
	 background: #343a40;
	 color: white;
	 font-size: 0.9em;
	 padding-top: 25px;
	 padding-bottom: 25px;
}
 footer a {
	 color: white;
}
 footer a:hover {
	 color: white;
	 text-decoration: underline;
}
 h1, h2, h3, h4, h5, h6 {
	 font-weight: 300;
}
 h1 {
	 font-size: 2em;
	 padding-top: 30px;
}
 h2 {
	 font-size: 1.6em;
	 padding-top: 15px;
}
 h3 {
	 font-size: 1.6em;
	 padding-top: 10px;
}
 .fab, .fas {
	 padding-right: 10px;
}
 .card-wrapper {
	 padding: 20px;
}
 #subnavbar {
	 background: #ea5735;
}
 #subnavbar button {
	 color: #fff;
}
 #subnavbar button:hover {
	 text-decoration: underline;
}
 #subnavbar .active {
	 text-transform: uppercase;
	 font-weight: bold;
	 color: white;
}
 #mainnavbar {
	 background: #3db2e2;
}
 #mainnavbar a {
	 color: #fff;
}
 #mainnavbar a:hover {
	 text-decoration: underline;
}
 #mainnavbar .active {
	 text-transform: uppercase;
	 font-weight: bold;
	 color: white;
}
 .img-links {
	 border: 1px solid lightgray;
}
 .video-react .video-react-big-play-button {
	 margin: auto;
	 top: 0;
	 bottom: 0;
	 left: 0;
	 right: 0;
}
 .card-body {
	 height: 190px;
}
 .table tbody tr td {
	 vertical-align: middle;
}
 .table tbody tr td {
	 vertical-align: middle;
}
 .card-body-notext {
	 height: 70px;
}
 #navbarSupportedContent ul li {
	 padding-left: 10px;
}
 